body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-message {
  color: red
}

/* 一覧のアイコン、ボタンなどのポインタ表示 */
.MuiDataGrid-row svg {
  cursor: pointer;
}

/* MUI steper表示の横棒のサイズ固定 */
.css-j5w0w9-MuiStepConnector-root {
  min-width: 8px;
  max-width: 8px;
}

/* MUI ボタンの幅を内容に合わせる */
.MuiButtonBase-root {
  min-width: max-content;
  white-space: nowrap;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: inherit !important;
}

/* DataGridに収まらない場合のPopupを一番上に表示 */
.css-advny6-MuiPopper-root {
  z-index: 9999
}

/* スクロールバー　青系にカスタマイズ */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(120, 170, 210, 0.3);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #78aad2;
  border-radius: 100px;
  width: 130px;
}
