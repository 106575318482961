.delete-icon {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
}

.oshirase-naiyo {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.8) !important;
  border: rgba(0, 0, 0, 0.5) solid 1px;
  min-height: 35vh;
  white-space: pre-line;
}

.card-info {
  border-left: 5px blue solid;
}

.card-notice {
  border-left: 5px orange solid;
}

.card-warning {
  border-left: 5px red solid;
}

.card-kidoku {
  background-color: rgba(0, 0, 0, 0.1) !important;
}