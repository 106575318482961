.container {
  max-width: 36rem;
  padding: 0 1rem;
  margin: 3rem auto 6rem;
}

.menu {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.menuActive {
  background-color: #99ccff !important;
}

.count {
  display: inline-block;
  min-width: 12px;
  padding: 3px 9px;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  text-align: center;
  background-color: #E68031;
  border: 1px solid transparent;
  border-radius: 2em;
  margin-right: 6px;
  z-index: 1;
}

.accountCount {
  display: inline-block;
  position: absolute;
  right: -5px;
  top: 0px;
  min-width: 12px;
  padding: 3px 9px;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
  line-height: 1.5;
  text-align: center;
  background-color: #E68031;
  border: 1px solid transparent;
  border-radius: 2em;
  margin-right: 6px;
  z-index: 1;
}

.acountMenu {
  justify-content: flex-end !important;
}