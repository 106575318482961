.thumbnail-text {
  max-width: 220px;
  padding: 5px;
  font-size: smaller;
  background-color: white;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.thumbnail-text p {
  margin: 0;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bangumihyo-dialog .MuiDialog-container {
  align-items: center;
}

.content-dialog .MuiDialog-container {
  align-items: flex-start;
}

.contents {
  display: flex;
  flex-flow: column;
  cursor: pointer;
}

.contents.selected {
  outline: dashed 3px rgba(0, 0, 255, 0.5);
  outline-color: #1976d2;
}
